import React from 'react';

const NoticeOfAgreement = () => (
  <ul>
    <li>
      This serves to inform you in case the insured deceased, AMAFU Family Burial Society will be
      responsible to pay the cover amount within 24 Hours as agreed by the two parties.
    </li>
    <li>
      Note that the claim cover is for cash no services are provided by the Society.
    </li>
      All members are to complete membership application forms. Beneficiaries may be nominated.
    <li>
      The premium has a 6 months waiting period before a claim can be approved.
    </li>
    <li>
      There is a (24) months waiting period on death due to suicide
    </li>
    <li>
      No waiting period will apply for Accidental death. Cover is immediate on receipt of 1st premium.
    </li>
    <li>
      No age restrictions.
    </li>
    <li>
      The Original or a Certified copy of the original death certificate of the Assured Life; proof of identity of
      the Assured Life; either the policy certificate, if available, or the application form; an official police
      report in the case of the death of the Assured Life due to unnatural causes; a medical report in the case
      of stillborn babies, indicating that the pregnancy reached the 26th (twenty-sixth) week; fully
    </li>
    <li>
      All monthly premiums are payable within no later than the date which the client has agreed/signed on
      the document (from1st – 7th of every month).
    </li>
    <li>
      If you skip 1 (one) month’s premium then you must pay double before the 7th of the following month
      and the policy will remain in force.
    </li>
    <li>
      If you skip 2 (two) months’ premium the policy will be cancelled and you will start as a new member
      paying the new premium and undergo the waiting period.
    </li>
    <li>
      Nobody will be covered without a valid ID document or Passport, a Birth Certificate (children) or
      certified copies thereof
    </li>
    <li>
      Members added after the start of this policy will undergo the same Waiting Period but calculated from
      the date of their submissions
    </li>
    <li>
      The Waiting Period will be calculated after the First monthly Premium is paid in full.
    </li>
    <li>
      Joining fee is R200.00 for all our funeral cover/policies. It’s advisable that you settle it with your 1st
      monthly premium so that your waiting period does not extend to 4 months.
    </li>
    <li>
      All deposit slips must be faxed or emailed
    </li>
  </ul>
);

export default NoticeOfAgreement;
