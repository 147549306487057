import React, { useEffect, useState, useCallback } from 'react';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import 'semantic-ui-css/semantic.min.css';
import { Button, Input, Card, Icon, Modal, Checkbox } from 'semantic-ui-react';
import firebase from 'gatsby-plugin-firebase'

import Header from '../../components/Header';
import Layout from '../../components/layout';
import { navigate } from 'gatsby';
import NoticeOfAgreement from '../../components/NoticeOfAgreement';

function pad(n, width) {
  const z = '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

const MAX_DEPENDANTS = 10;
const FIELDS = {
  // primary member
  firstNames: 'firstNames',
  surname: 'surname',
  // idNumber: 'idNumber',
  dateOfBirth: 'dateOfBirth',
  // contact details
  physicalAddress: 'physicalAddress',
  cellNumber: 'cellNumber',
  workNumber: 'workNumber',
  emailAddress: 'emailAddress',
  // misc
  referralCode: 'referralCode',
  funeralCover: 'funeralCover',
};

const Register = () => {
  const [dependants, setDependants] = useState([0]);
  const { register, handleSubmit, getValues, setValue, trigger, errors, unregister } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [open, setOpen] = useState(false);
  const [agreementAccepted, setAgreementAccepted] = useState(false);

  const onSubmit = useCallback(async () => {
    if (!agreementAccepted) {
      setOpen(true);
      return;
    }

    const payload: any = {};
    const formValues = getValues();

    const dependantValues = dependants.map((index) => ({
      surname: formValues[`dependant${index}surname`],
      fullNames: formValues[`dependant${index}fullNames`],
      relationship: formValues[`dependant${index}relationship`],
      cover: formValues[`dependant${index}cover`],
    }));

    Object.keys(FIELDS).forEach((key) => payload[key] = formValues[key]);
    payload.dependants = dependantValues;

    try {
      setIsSubmitting(true);
      await firebase.firestore().collection('registrationsCounter').get().then(async (querySnapshot) => {
        const currentCounter = querySnapshot.docs[0].data().counter;
        payload.policyNumber = `M${pad(currentCounter, 4)}`;
        await firebase.firestore().collection('registrationsCounter').doc(querySnapshot.docs[0].id).update({ counter: currentCounter + 1 });
        await firebase.firestore().collection('registrations').add(payload);
      });
      navigate('/submit-success');
    } catch (error) {
      alert('Failed to submit your form. Please check your network connection and try again');
      setIsSubmitting(false);
    }
  }, [agreementAccepted, getValues]);
  const onChange = useCallback(async (_event, { name, value }) => {
    if (name === FIELDS.funeralCover) {
      setValue(name, !getValues().funeralCover);
    } else {
      setValue(name, value);
    }
    await trigger(name);
  }, [setValue, trigger]);
  const onAddDependant = useCallback(() => {
    const newDependant = dependants.length;

    register({ name: `dependant${newDependant}surname` }, { required: true });
    register({ name: `dependant${newDependant}fullNames` }, { required: true });
    register({ name: `dependant${newDependant}relationship` }, { required: true });
    register({ name: `dependant${newDependant}cover` }, { required: true });
    setDependants(dependants.concat(newDependant));
  }, [dependants, register]);
  const onRemoveDependant = useCallback((dependant) => () => {
    unregister(`dependant${dependant}surname`);
    unregister(`dependant${dependant}fullNames`);
    unregister(`dependant${dependant}relationship`);
    unregister(`dependant${dependant}cover`);
    setDependants(dependants.filter((dep) => dep !== dependant));
  }, [dependants, unregister]);

  useEffect(() => {
    Object.keys(FIELDS).forEach((key) => register({ name: key }, { required: true }));
    dependants.forEach((index) => {
      register({ name: `dependant${index}surname` }, { required: true });
      register({ name: `dependant${index}fullNames` }, { required: true });
      register({ name: `dependant${index}relationship` }, { required: true });
      register({ name: `dependant${index}cover` }, { required: true });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout title="Register" >
      <Header title="Register" subtitle="Complete the form to sign up" />
      <div id="main">
        <section id="content" className="main">
          <p>Please complete all fields, or <a style={{ color: '#e07985' }} className="download" href="/application-form.pdf" download>click here to download</a> the form instead</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p>Principal member details</p>
            <Input
              error={Boolean(errors[FIELDS.surname])}
              fluid
              label="Surname"
              name={FIELDS.surname}
              onChange={onChange}
              placeholder="Surname"
              type="text"
            />
            <Input
              error={Boolean(errors[FIELDS.firstNames])}
              fluid
              label="First Names"
              name={FIELDS.firstNames}
              onChange={onChange}
              placeholder="First Names"
              type="text"
            />
            <Input
              error={Boolean(errors[FIELDS.dateOfBirth])}
              fluid
              label="Date of Birth"
              name={FIELDS.dateOfBirth}
              onChange={onChange}
              placeholder="Date of Birth"
              type="date"
            />
            <Checkbox
              label="Include funeral/burial cover?"
              name={FIELDS.funeralCover}
              onChange={onChange}
              toggle
            />
            <p>Contact Details</p>
            <Input
              error={Boolean(errors[FIELDS.physicalAddress])}
              fluid
              label="Physical Address"
              name={FIELDS.physicalAddress}
              onChange={onChange}
              placeholder="Physical Address"
              type="text"
            />
            <Input
              error={Boolean(errors[FIELDS.cellNumber])}
              fluid
              label="Cell Number"
              name={FIELDS.cellNumber}
              onChange={onChange}
              placeholder="Cell Number"
              type="text"
            />
            <Input
              error={Boolean(errors[FIELDS.workNumber])}
              fluid
              label="Work Number"
              name={FIELDS.workNumber}
              onChange={onChange}
              placeholder="Work Number"
              type="text"
            />
            <Input
              error={Boolean(errors[FIELDS.emailAddress])}
              fluid
              label="Email Address"
              name={FIELDS.emailAddress}
              onChange={onChange}
              placeholder="Email Address"
              type="email"
            />
            <Input
              error={Boolean(errors[FIELDS.referralCode])}
              fluid
              label="Referral Code"
              name={FIELDS.referralCode}
              onChange={onChange}
              placeholder="Who referred you (M000X)"
              type="text"
            />
            <p>Dependants</p>
            <Card.Group>
              {dependants.map((dependant, index) => (
                <Card key={`dependants-${dependant}`}>
                  <Card.Content>
                    <Card.Header>
                      Dependant {index + 1}
                      {index > 0 && (
                        <Icon
                          color="red"
                          name="close"
                          onClick={onRemoveDependant(dependant)}
                          size="small"
                          title="Remove dependant"
                        />
                      )}
                    </Card.Header>
                    <div className="form">
                      <Input
                        error={Boolean(errors[`dependant${dependant}surname`])}
                        label="Surname"
                        name={`dependant${dependant}surname`}
                        onChange={onChange}
                        placeholder="Surname"
                        type="text"
                      />
                      <Input
                        error={Boolean(errors[`dependant${dependant}fullNames`])}
                        label="Full Names"
                        name={`dependant${dependant}fullNames`}
                        onChange={onChange}
                        placeholder="Full Names"
                        type="text"
                      />
                      <Input
                        error={Boolean(errors[`dependant${dependant}relationship`])}
                        label="Relationship"
                        name={`dependant${dependant}relationship`}
                        onChange={onChange}
                        placeholder="Relationship"
                        type="text"
                      />
                      <Input
                        error={Boolean(errors[`dependant${dependant}cover`])}
                        label="Cover"
                        name={`dependant${dependant}cover`}
                        onChange={onChange}
                        placeholder="Cover"
                        type="text"
                      />
                    </div>
                  </Card.Content>
                </Card>
              ))}
              {dependants.length < MAX_DEPENDANTS && (
                <Card
                  className="add-dependant"
                  onClick={onAddDependant}
                  title="Add a new dependant to your cover"
                >
                  <Icon name="add" size="large" color="blue" />
                  Add dependant
                </Card>
              )}
            </Card.Group>
            <div className="submit">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Submit
              </Button>
              <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={<Button type="button" color={agreementAccepted ? 'green' : undefined}>Read Notice of Agreement</Button>}
              >
                <Modal.Header>Notice of Agreement</Modal.Header>
                <Modal.Content image color="black">
                  <Modal.Description>
                    <NoticeOfAgreement />
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    type="button"
                    content="Decline"
                    color="red"
                    onClick={() => { setAgreementAccepted(false); setOpen(false); }}
                  />
                  <Button
                    type="button"
                    content="Agree"
                    labelPosition="right"
                    icon="checkmark"
                    onClick={() => { setAgreementAccepted(true); setOpen(false); }}
                    positive
                  />
                </Modal.Actions>
              </Modal>
            </div>
          </form>
        </section>
      </div>
    </Layout>
  );
};

export default Register;

